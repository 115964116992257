@use '../../../../node_modules/@make/ds2/scss/variables' as *;
@use '../../../../node_modules/@make/ds2/scss/mixins' as *;

.constructor-search-input__icon {
  position: absolute;
  right: 20px;
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;
  fill: $color-dividing-line;
}
