@use "~@make/ds2/scss/mixins" as *;

.constructor-page {

}

.constructor-block__container {
  padding-left: 50px;
  background: #D9D9DC;


  @include screen-small-down {
    overflow: hidden;
  }
}

.constructor-block__container--before {
  height: 1px;
}

.constructor-page__share-wrapper {
  #share-modal.active {
    z-index: 11 !important;
  }
}

.constructor-page__form-error {
  color: red;
  font-size: 12px;
}