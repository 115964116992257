@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/functions/typography" as *;
@use "~@make/ds2/scss/placeholders";

.constructor-component__banners {
  @include indent-bottom(32);
  @include indent-top(32);

  .banners__container {
    margin-top: 0;
  }
}

.constructor-component__banner-slide {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.constructor-component__banner-slide--width1 {
  grid-template-columns: 1fr 1fr;
}

.constructor-component__banner-slide--width2 {
  grid-template-columns: 1fr 1fr;

  @media (min-width: 736px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1088px) {
    grid-template-columns: repeat(4, 1fr);
  }
}


.constructor-component__banner-slide--width3 {
  grid-template-columns: 1fr 1fr;

  @media (min-width: 736px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1088px) {
    grid-template-columns: repeat(6, 1fr);
  }
}


.constructor-component__banner-slide--width4 {
  grid-template-columns: 4fr 1fr;

  @media (min-width: 736px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1088px) {
    grid-template-columns: repeat(8, 1fr);
  }
}

