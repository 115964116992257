.spoiler__button {
  color: var(--color-interactive-element);

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }

  &:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}
