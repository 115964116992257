@use "~@make/ds2/scss/components/site-menu";
@use "~@make/ds2/scss/components/site-submenu";
@use "~@make/ds2/scss/components/site-subsubmenu";

header {
  // height: 72px;
  // background: white;
  // margin: 8px;
}

.site-map__section-link,
.site-subsubmenu__link,
.links-list__link {
  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }

  &:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}

.site-subsubmenu__link.active:not(:hover):not(:active) {
  color: var(--color-interactive-element);
}
