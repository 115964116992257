.constructor-component-table {
  min-width: 100%;
  margin-bottom: 48px;
}

.constructor-component-table__head {

  th {
    padding-bottom: 11px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71717C;
    text-align: left;
    border-bottom: 1px solid #D9D9DC;
  }
}

.constructor-component-table__line {
  td {
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2B2B3C;
    text-align: left;
    border-bottom: 1px solid #E5E5E7;
  }
}