@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/placeholders" as *;

.constructor__work-zone-wrapper {
  min-height: 100vh;
  background: $color-light-gray-fill;
}

.constructor__work-zone {
  grid-column: 1 / span 2;
  min-height: calc(100vh - 70px);
  max-width: 100%;

  b {
    font-weight: 700;
  }

  & > :first-child {
    .constructor-component__wrapper.active {
      margin-top: 50px;
    }
  }
}

.constructor__work-zone-container {
  @include grid-standard;
  display: grid;
  @include screen-medium-up {
    .constructor__work-zone {
      grid-column: span 4;
    }
  }
;
}

.constructor__work-zone-container--help {
  @include screen-medium-up {
    .constructor__work-zone {
      grid-column: 4 span;
    }
  }

  .page-aside-menu__wrapper {
    grid-column: 1;
  }

  .anchor-menu__item--first {
    display: none;
  }

  .anchor-menu {
    border-left: none;
    margin-left: -24px;
  }

  .anchor-menu__indicator {
    right: 0;
    left: auto;
  }

  .page-aside-menu__wrapper {
    @include indent-outer-right(80);
    border-right: 1px solid #d9d9dc;
  }

  .anchor-menu__item {
    padding-right: 24px;
  }

  .constructor__left-bar-logo {
    margin-left: 0;
  }

  .constructor__left-bar-site-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71717C;
    opacity: 1;
  }

  .constructor__left-bar-site-domain {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #00001E;
    opacity: 1;
  }

  .constructor__left-bar-logo-icon {

  }
}

.constructor__work-zone-title {
  @include indent-outer-top(32);
  @include indent-outer-bottom(32);
}

.constructor__page-container {
  @extend %grid-container-base;
}


.constructor__work-zone-title-help {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #71717C;

  @include indent-outer-top(20);
  @include indent-outer-bottom(20);
}

.constructor__edit-blocked-message {
  padding: 10px;
  width: 100%;
  background: rgba(213, 25, 25, 0.5);
  color: white;
  z-index: 20;
  position: fixed;
  bottom: 0;
  height: 50px;
  display: flex;
  align-items: center;
}


.constructor__work-zone-breadcrumbs-block {
  display: flex;
  align-items: center;
  padding-top: 24px;
  grid-gap: 0;
}

.constructor__work-zone-breadcrumb {
  display: flex;
  align-items: center;
}

.constructor__work-zone-breadcrumb-icon {
  padding: 0 4px;
  font-size: 14px;
  line-height: 20px;
  color: #ADADB4;
}

.constructor__work-zone-breadcrumb-link {
  font-size: 14px;
  line-height: 20px;
  color: #2B2B3C;

  &:hover {
    color: var(--color-interactive-element);
  }
}

