.constructor-component__spoiler-with-name-wrapper {
  margin-bottom: 48px;
}

.constructor-component__spoiler-with-name {
  margin-bottom: 8px;

  .accordion__wrapper {
    padding: 0;
  }


  &.opened {
    .constructor-component__spoiler-with-name-name {
      background: white;
      border-color: white !important;
      border-bottom-color: #D9D9DC !important;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #00001E;
    }
  }
}

.constructor-component__spoiler-with-name-name {
  width: 100%;
  padding: 24px;
  border: 1px solid #D9D9DC !important;
  border-radius: 2px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #2B2B3C;

  &:hover {
    color: #0050B2;
  }

  .content-block--editor {
    width: 100%;
  }
}

.constructor-component__spoiler-with-name-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #2B2B3C;
  padding: 24px;
  background: white;
}