@use "node_modules/@make/ds2/scss/variables" as *;

.constructor-add-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-interactive-element;
  border-color: $color-interactive-element-outline;
  border-style: solid;
  border-width: 1px;
  padding: 12px 16px;
  border-radius: 2px;
  transition: border-color 0.15s ease;
  width: 100%;
  height: 48px;

  &:hover {
    border-color: $color-interactive-element;
  }
}

.constructor-add-button--big {
  padding: 24px;

  .constructor-add-button__title {
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
  }

  .constructor-add-button__icon {
    width: 28px;
    height: 28px;
  }
}

.constructor-add-button__title {
  font-size: 16px;
  line-height: 24px;
}

.constructor-add-button__icon {
  width: 20px;
  height: 20px;
  fill: $color-interactive-element;
}

