.constructor-component__line-separator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 39px;
}

.constructor-component__line-separator {
  background: #D9D9DC;
  height: 1px;
  width: 100%;
  //max-width: 920px;
}