.constructor-component__stages-block {
  margin-bottom: 40px;
}

.constructor-component__stage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 40px;
  position: relative;

  &:last-of-type {
    .constructor-component__stage-line {
      display: none;
    }
  }
}

.constructor-component__stage-number-block {
  width: 32px;
  position: relative;
  height: 100%;
}

.constructor-component__stage-number {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #2B2B3C;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  z-index: 1;
  color: #FFFFFF;
}

.constructor-component__stage-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  left: 15px;
  height: 100%;
  background: #2B2B3C;
}

.constructor-component__stage-content {
  margin-left: 24px;
  width: 100%;
}

.constructor-component__stage-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #00001E;
  margin-bottom: 20px;
}

.constructor-component__stage-text {

}