@use "../../../../node_modules/@make/ds2/scss/mixins" as *;

.constructor-youtube-player {
  position: relative;
  padding-top: 52%;
}

.constructor-youtube-player__video {
  position: absolute;
  top: 0;
  left: 0;
}
