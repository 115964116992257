@use "node_modules/@make/ds2/scss/variables" as *;
@use "node_modules/@make/ds2/scss/mixins" as *;

.constructor-file-list__item {
  display: grid;
  @include grid-medium-up(2 6 2);
  gap: 20px;
  @include indent-bottom(16);
  border-bottom: 1px solid $color-dividing-line;

  &:not(:last-child) {
    @include indent-outer-bottom(16);
  }
}

.constructor-file-list__image {
  height: 92px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.constructor-file-list__name {
  font-size: 16px;
  line-height: 24px;
}

.constructor-file-list__controls {
  display: flex;
  align-self: start;
  align-items: center;
}

.constructor-file-list__button {
}

.constructor-file-list__button-icon {
  width: 24px;
  height: 24px;
}

.constructor-file-list__input {
  display: none;
  visibility: hidden;
}
