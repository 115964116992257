@use "node_modules/@make/ds2/scss/variables" as *;
@use "node_modules/@make/ds2/scss/mixins" as *;

.top-bar__components-list-overlay {
  position: fixed;
  left: 0;
  top: 59px;
  right: 0;
  bottom: 0;
  background-color: rgba($color-field-frame, 0.32);
  pointer-events: none !important;
}

.top-bar__components-list {
  position: fixed;
  left: 55px;
  top: 59px;
  bottom: 8px;
}

.top-bar__components-list-wrapper {
  position: relative;
  height: 100%;
  width: 480px;
  z-index: 3;
}

.top-bar__components-group {
  background-color: $color-white;
  height: 100%;
  width: 240px;
  @include indent-top(12);
}

.top-bar__components-group-button {
  position: relative;
  width: 100%;
  padding: 6px 16px;
  box-shadow: none !important;
  border: none !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: $color-light-gray-fill;

    &:before {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      content: '';
      background-color: $color-interactive-element;
    }
  }
}

.top-bar__components-group-button-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $color-text;
}

.top-bar__components-group-button-icon {

  svg {
    width: 10px;
    height: 6px;
    transform: rotate(-90deg);
  }
}

.top-bar__components-group-list {
  position: absolute;
  top: 0;
  bottom: 0;
  //left: calc(100% + 1px);
  right: -1px;
  width: 240px;
  background: $color-white;
  overflow: hidden;
  opacity: 0;
  transform: translateX(-60px);
  transition: transform 0.15s ease, opacity 0.1s ease;
  pointer-events: none;
  z-index: -1;
  overflow-y: auto;

  &.active {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
  }
}

.top-bar__components-group-list-wrapper {
  padding: 12px;
}


.top-bar__component-add-block {
  width: 100%;
  height: 120px;
  background: $color-light-gray-fill;
  margin-bottom: 12px;
  padding: 8px 12px;
  overflow: hidden;
  cursor: move;

  &:hover {
    background: $color-dividing-line-light;
  }
}

.top-bar__component-add-block-title {
  font-size: 14px;
  line-height: 20px;
  color: $color-heading;
}

.top-bar__component-add-block-preview {
  padding-top: 16px;
  padding-right: 12px;
  padding-left: 12px;

  img {
    width: 100%;
    height: auto;
  }
}
