@use "node_modules/@make/ds2/scss/variables" as *;

$day-size: 24px !default;

.constructor-date-picker__wrapper {
  position: relative;
  z-index: 3;

  input:focus {
    box-shadow: none;
  }

  &.focused {
    z-index: 4;
  }
}

.constructor-date-picker__button {
  position: absolute;
  right: 12px;
  top: calc(50% - 12px);

  &:hover .constructor-date-picker__button-icon {
    fill: $color-text;
  }
}

.constructor-date-picker__button-icon {
  width: 24px;
  height: 24px;
  fill: $color-hint;
  transition: fill 0.15s ease;

  &.active {
    fill: $color-text;
  }
}

.constructor-date-picker__picker-wrapper {
  position: absolute;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease;
  z-index: 1;
  margin-top: 4px;
  background-color: $color-white;
  box-shadow: 0 10px 12px rgba($color-heading, 0.04);
  border-radius: 2px;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .litepicker {
    padding: 16px 24px;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 1px;
      background-color: $color-dividing-line-light;
      content: '';
      margin: 24px 0;
    }

    .container__months {
      display: flex;
      gap: 0;
    }

    .month-item-header {
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    .month-item-name {
      text-transform: capitalize;
      margin-right: 4px;
      font-weight: 600;
    }

    .button-previous-month,
    .button-next-month {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.1s linear;
      width: 20px;
      height: 20px;

      &:hover, &:focus {
        background-color: $color-light-gray-fill;
      }

      .icon {
        width: 8px;
        height: 8px;
        fill: $color-text;
        pointer-events: none;
      }
    }

    .button-previous-month {
      margin-right: auto;

      .icon {
        transform: rotate(90deg);
      }
    }

    .button-next-month {
      margin-left: auto;

      .icon {
        transform: rotate(-90deg);
      }
    }

    .month-item-weekdays-row {
      display: none;
    }

    .month-item {
      width: $day-size * 7;
    }

    .month-item + .month-item {
      margin-left: 24px;
    }

    .container__days {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $day-size;
        height: $day-size;

        &:empty:before {
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $color-dividing-line-light;
          content: "";
        }
      }
    }

    .day-item {
      font-size: 12px;
      line-height: typo-text-line-height(12px);
      transition: background-color 0.1s linear, color 0.1s linear;
      cursor: pointer;

      &:hover, &:focus {
        background-color: $color-light-gray-fill;
        outline: none;
      }


      &.is-in-range {
        background-color: rgba($color-interactive-element, 0.12);
      }

      &.is-today {
        background-color: $color-success;
        color: $color-white;
      }

      &.is-start-date,
      &.is-end-date {
        background-color: $color-interactive-element;
        color: $color-white;
        transition-duration: 0s, 0s;
      }
    }
  }

  &:not(.admin-input) {
    .day-item {
      &.is-in-range {
        background-color: var(--color-interactive-element-hover-rgba);
      }

      &.is-start-date,
      &.is-end-date {
        background-color: var(--color-interactive-element);
      }
    }

  }

}
