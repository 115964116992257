.constructor-dropdown-wrapper {
  opacity: 0;
  transition: opacity 0.25s linear;
  z-index: -1;
  pointer-events: none;

  &.open {
    opacity: 1;
    pointer-events: all;
    z-index: 1;
  }
}

.constructor-dropdown {
  transform: translateY(-5px);
  opacity: 0;
  height: auto;
  transition: all 0.25s linear;
  pointer-events: none;

  &.open {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }
}
