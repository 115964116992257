@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;
@use "../../../../node_modules/@make/ds2/scss/placeholders";

.constructor-contact-person-item {
  display: grid;
  @include grid-medium-up(2 1);
  row-gap: 0;
  column-gap: 40px;
  position: relative;
  z-index: 1;

  &:not(:last-child) {
    border-bottom: 1px solid $color-dividing-line-light;
    @include indent-bottom(24);
    @include indent-outer-bottom(24);
  }

  &.main-item {
    @include screen-large-up {
      grid-template-columns: 5fr 3fr;
    }

    .constructor-contact-person-item__image-wrapper {
      @include screen-large-up {
        max-width: 100%;
        height: auto;
        padding-bottom: 100%;
        margin-left: 0;
        position: relative;
        overflow: hidden;
      }
    }

    .constructor-contact-person-item__image {
      @include screen-large-up {
        width: 100%;
        height: auto;
        object-fit: cover;
        position: absolute;
      }
    }

    .constructor-contact-person-item__last-name {
      @include typo-make-sizes($typo-heading-h2-sizes, $isHeading: true);
    }

    .constructor-contact-person-item__name {
      @include typo-make-sizes($typo-heading-h2-sizes);
    }
  }

  .accordion {
    position: initial;
    z-index: 0;
  }

  .accordion__button {
    color: var(--color-interactive-element);
    padding: 0;

    .icon {
      fill: var(--color-interactive-element);;
    }
  }

  .accordion__text {
    color: var(--color-interactive-element);
  }
}

.constructor-contact-person-item__image-wrapper {
  max-width: 200px;
  height: 100%;
  margin-left: auto;
  overflow: hidden;

  @include screen-small-down {
    order: -1;
    margin-left: 0;
    margin-bottom: 16px;
    max-width: 100%;
  }
}

.constructor-contact-person-item__image {
  width: auto;
  height: auto;

  @include screen-small-down {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.constructor-contact-person-item__name {
  @include typo-make-sizes($typo-paragraph-lead-sizes);
  @include indent-outer-bottom(12);
}

.constructor-contact-person-item__position {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
  @include indent-outer-bottom(24);
}

.constructor-contact-person-item__footer {
  display: flex;
  gap: 0;

  @include screen-small-down {
    flex-direction: column;
  }
}

.constructor-contact-person-item__footer--with-accordion {
  @include indent-outer-bottom(24);
}

.constructor-contact-person-item__social-links {
  @include screen-small-down {
    @include indent-outer-bottom(24);
  }

  @include screen-medium-up {
    @include indent-right(122);
  }
}

.constructor-contact-person-item__link {
  display: flex;
  gap: 0;
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  transition: color 0.15s ease;

  &:not(:last-child) {
    @include indent-outer-bottom(12);
  }

  &:hover {
    color: var(--color-interactive-element-hover);

    .constructor-contact-person-item__icon {
      fill: var(--color-interactive-element-hover);
    }
  }

  &:active {
    color: var(--color-interactive-element-active);

    .constructor-contact-person-item__icon {
      fill: var(--color-interactive-element-active);
    }
  }
}

.constructor-contact-person-item__link {
  align-items: center;
}

.constructor-contact-person-item__icon {
  width: 24px;
  height: 24px;
  @include indent-outer-right(12);
  transition: fill 0.15s ease;
}

.constructor-contact-person-item__contacts {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.constructor-contact-person-item__email {
  color: var(--color-interactive-element);
  transition: color 0.15s ease;

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }
}

.constructor-contact-persons {
  grid-column: 1 / span 2;

  .accordion {
    &.opened {
      .accordion__wrapper {
        position: relative;
        align-items: start;

        &::before {
          @include indent-outer-horizontal-pair(40);
          @include indent-outer-top(32);
          content: "";
          height: 1px;
          width: calc(100% - 80px);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          display: block;
          background-color: $color-dividing-line;

          @include screen-small-down {
            @include indent-outer-horizontal-pair(32);
            margin-top: 0;
            width: calc(100% - 48px);
          }
        }
      }

      .accordion__button {
        .icon {
          fill: var(--color-interactive-element);
        }
      }
    }

    &:before {
      @include indent-outer-horizontal-pair(-32);
    }
  }

  .wave-wrapper {
    @include indent-outer-horizontal-pair(-32);
  }

  .accordion__wrapper {
    @include indent-outer-horizontal-pair(-32);
    display: grid;
    grid-template-columns: 1fr 3fr;

    @include screen-small-down {
      grid-template-columns: 4fr;
      display: flex;
      flex-direction: column-reverse;
    }

    @include screen-medium-up {
      @include indent-outer-horizontal-pair(-40);
    }

    .person-info {
      flex-direction: row-reverse;
      justify-content: space-between;
      display: flex;

      .person-info__wrapper {
        padding-left: 0;
      }
    }

    .person__subordinates {
      @include screen-small-down {
        @include screen-small-down
      }
    }

    .constructor-contact-persons .person__subordinate {
      @include screen-small-down {
        margin-top: 16px;
      }
    }

    .accordion__content-wrapper {
      @include screen-small-down {
        margin-top: 0;
      }
    }
  }

  .accordion__content-wrapper {
    @include indent-outer-top(32);
  }

  .person__subordinate {
    @include indent-outer-bottom(20);
    border-bottom: 1px solid $color-dividing-line-light;
  }

  .government-structure__department-link-wrapper {

    &:not(:first-child) {
      @include indent-outer-top(24);
    }

    .government-structure__department-link {
      color: var(--color-interactive-element);

      &:hover {
        color: var(--color-interactive-element-hover);
      }

      &:active {
        color: var(--color-interactive-element-active);
      }

      &:focus {
        outline-color: var(--color-interactive-element-rgba);
      }

      @include indent-bottom(20);
      border-bottom: 1px solid $color-dividing-line-light;

      &:not(:first-child) {
        @include indent-outer-top(20);
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .person__subordinates {
    @include indent-top(24);
  }
}

.person-info__title {
  @extend %typo-base-text-link;
  @include typo-make-sizes($typo-paragraph-text-sizes);
  width: 100%;

  color: var(--color-interactive-element);

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }

  &:focus {
    outline-color: var(--color-interactive-element-rgba);
  }
}

.constructor-contact-person-item .accordion__content-wrapper .government-structure__department-link-wrapper:only-child {
  @include indent-top(20);
}
