@use "~@make/ds2/scss/variables" as *;

.structure-list__overlay {
  position: fixed;
  top: 59px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-field-frame, 0.32);
}

.structure-list__wrapper {
  position: fixed;
  left: 130px;
  top: 59px;
  bottom: 8px;
  overflow: hidden;
  background-color: $color-white;
}

.structure-list__components-list-wrapper {
  width: 320px;
}

.structure-list__components-list {
  padding-top: 16px;
  height: calc(100vh - 133px);
  overflow: auto;
}

.structure-list__component {
  position: relative;

  .structure-list__component:not(.structure-list__component--4) {
    margin-left: 16px;
  }
}

.structure-list__component:not(.structure-list__component--1) {
  .structure-list__link {
    padding-left: 18px;
  }

  .structure-list__dropdown-button + .structure-list__link {
    padding-left: 8px;
  }
}

.structure-list__component--2:not(:last-child),
.structure-list__component--4:not(:last-child) {
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';
    width: 1px;
    background-color: $color-dividing-line;
    z-index: 1;
  }
}

.structure-list__component--4:first-child {
  &:before {
    top: -12px;
    z-index: 1;
  }
}

.structure-list__component--2:last-child,
.structure-list__component--4:last-child {
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    content: '';
    width: 1px;
    background-color: $color-dividing-line;
    z-index: 1;
  }
}

.structure-list__component--2:not(.structure-list__component--with-child),
.structure-list__component--4 {
  & > .structure-list__item-wrapper .structure-list__link {
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: 1px;
      content: '';
      background-color: $color-dividing-line;
    }
  }
}

.structure-list__component--4 {
  .structure-list__link {
    font-size: 14px;
    line-height: 20px;
  }
}

.structure-list__item-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  &:hover,
  &.current {
    &:before {
      position: absolute;
      top: 0;
      left: -50px;
      right: 0;
      bottom: 0;
      background-color: $color-light-gray-fill;
      content: '';
      border-right: 2px solid $color-interactive-element;
      z-index: 0;
      margin-left: 0;
    }
  }
}

.structure-list__link {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 8px 0 8px 10px;
}

.structure-list__dropdown-button {
  margin-left: -10px;
}

.backLink__wrapper {
  padding: 20px 16px;
  border-bottom: 1px solid $color-dividing-line;
}

.structure-list__child-list {
  height: 100%;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.25s ease, opacity 0.1s ease;

  &.active {
    max-height: 2000px;
    opacity: 1;
  }
}
