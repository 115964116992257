@use "~@make/ds2/scss/variables" as *;

.constructor-switch {
  position: relative;
  width: 36px;
  height: 20px;
  border-radius: 10px;
  background-color: $color-field-frame;
  border: 1px solid transparent;
  transition: background-color 0.15s ease, border-color 0.15s ease;

  &:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: 0 2px 2px rgba($color-heading, 0.24);
    z-index: 2;
    transition: transform 0.15s ease;
  }

  &.active {
    border-color: $color-white;
    background-color: $color-success;

    &:before {
      transform: translateX(100%);
    }
  }


}
