@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.constructor-document-preview {

}

.constructor-document-preview__title {
  @include indent-outer-bottom(24);
}
