.constructor-component__quote {
  width: 100%;
  background: white;
  border-radius: 2px;
  padding: 32px 40px 40px 40px;
  margin-bottom: 48px;
}

.constructor-component__quote-text {
  font-size: 28px;
  line-height: 44px;
  color: #2B2B3C;
}

.constructor-component__quote-author {
  display: flex;
  margin-top: 40px;
  justify-content: flex-start;
  align-items: flex-start;

}

.constructor-component__quote-author-image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 24px;

  img {
    width: 100%;
  }
}

.constructor-component__quote-author-text {

}

.constructor-component__quote-author-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00001E;
}

.constructor-component__quote-author-post {
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #71717C;
}