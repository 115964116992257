.move-page-dialog__wrapper {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(173, 173, 180, 0.48);
  z-index: 20;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }
}

.move-page-dialog {
  position: absolute;
  background: #F1F1F2;
  border-radius: 4px;
  padding: 16px 32px 48px;
  width: 608px;

  .constructor-input-field__label-wrapper {
    display: none;
  }
}

.move-page-dialog__hint {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #71717C;
}

.move-page-dialog__name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #00001E;
  margin-bottom: 24px;
}

.move-page-dialog__buttons {
  margin-top: 24px;

  .constructor-button {
    margin-right: 32px;
  }
}