@use "~@make/ds2/scss/variables/colors" as *;

.constructor-sidebar__container {
  position: fixed;
  top: 0;
  left: 48px;
  width: 240px;
  height: 100vh;
  border-right: 1px solid $color-dividing-line;
  background-color: #fff;
}

.constructor-sidebar__links {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 24px;
}

.constructor-sidebar__header {
  padding: 16px 0 16px 16px;
  font-size: 14px;
  line-height: 20px;
}

.constructor-sidebar__backLink {
  padding: 16px 0 16px 16px;
}

.constructor-sidebar__link {
  padding: 8px 16px;
  transition: background-color 0.15s ease;
  cursor: pointer;

  &.active,
  &:hover {
    background-color: $color-light-gray-fill;
    border-right: 2px solid $color-interactive-element;
  }
}
