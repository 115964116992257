@use "~@make/ds2/scss/variables" as *;

.structure-editable-list__component {
  position: relative;

  .structure-editable-list__component:not(.structure-editable-list__component--1) {
    margin-left: 32px;

    &.structure-editable-list__component--with-child > .structure-editable-list__item-wrapper {
      margin-left: 0;
    }

    &.structure-editable-list__component--2 {
      margin-left: 12px;
    }
  }
}

.structure-editable-list__component:not(.structure-editable-list__component--1) {
  &:not(.structure-editable-list__component--with-child) > .structure-editable-list__item-wrapper {
    padding-left: 20px;
  }

  &.structure-editable-list__add-button {
    padding-left: 20px;
  }
}

.structure-editable-list__component--1 .structure-editable-list__item-wrapper {
  padding-top: 20px;
}

.structure-editable-list__component--2 .structure-editable-list__item-wrapper {
  padding-top: 16px;
}

.structure-editable-list__component--3 .structure-editable-list__item-wrapper {
  padding-top: 12px;
}

.structure-editable-list__component--4 .structure-editable-list__item-wrapper {
  padding-top: 8px;
}

.structure-editable-list__component--2.structure-editable-list__add-button {
  padding-top: 16px;

  &:before {
    height: calc(50% + 8px);
  }

  &:after {
    top: calc(50% + 8px);
  }
}

.structure-editable-list__component--3.structure-editable-list__add-button {
  padding-top: 12px;

  &:before {
    height: calc(50% + 6px);
  }

  &:after {
    top: calc(50% + 6px);
  }
}

.structure-editable-list__component--4.structure-editable-list__add-button {
  padding-top: 8px;

  &:before {
    height: calc(50% + 4px);
  }

  &:after {
    top: calc(50% + 4px);
  }
}

.structure-editable-list__component:not(.structure-editable-list__component--1):not(:last-child) {
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';
    width: 1px;
    background-color: $color-dividing-line;
    z-index: 1;
  }
}

.structure-editable-list__component:not(.structure-editable-list__component--1):last-child {
  &:before {
    position: absolute;
    top: 0;
    bottom: calc((100% - 8px) / 2);
    left: 0;
    content: '';
    width: 1px;
    background-color: $color-dividing-line;
    z-index: 1;
  }
}

.structure-editable-list__component--4:first-child {
  &:before {
    top: 0;
    z-index: 1;
  }
}

.structure-editable-list__component:not(.structure-editable-list__component--1) {
  & > .structure-editable-list__item-wrapper {
    &:before {
      position: absolute;
      left: 0;
      width: 20px;
      height: 1px;
      content: '';
      background-color: $color-dividing-line;
    }
  }
}

.structure-editable-list__add-button {
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    background-color: $color-dividing-line;
    content: '';
  }

  &:after {
    position: absolute;
    left: 0;
    width: 20px;
    height: 1px;
    content: '';
    background-color: $color-dividing-line;
  }
}

.structure-editable-list__component--4 {
  .structure-editable-list__link {
    font-size: 16px;
    line-height: 24px;
  }
}

.structure-editable-list__item-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.structure-editable-list__link-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $color-dividing-line-light;
  padding: 12px 16px;
  transition: all 0.15s ease;

  &:hover,
  &.hovered {
    background-color: $color-white;
    border-color: $color-white;
    box-shadow: 0 16px 32px rgba($color-heading, 0.04);
    font-weight: 600;

    & > .structure-editable-list__menu-button {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.structure-editable-list__link {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
}

.structure-editable-list__component--1 > .structure-editable-list__item-wrapper {
  .structure-editable-list__link-wrapper {
    pointer-events: none;
    font-weight: 600;
    color: $color-field-frame;
  }
}

.structure-editable-list__dropdown-button {
  margin-right: 8px;
  margin-left: -12px;
}

.structure-editable-list__child-list {
  height: 100%;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: max-height 0.25s ease, opacity 0.1s ease;

  &.active {
    max-height: 5000px;
    opacity: 1;
    pointer-events: all;
    padding-bottom: 24px;
  }
}

.structure-editable-list__menu-button {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  opacity: 0;
  transition: opacity 0.15s ease;
}

.structure-editable-list__menu-button-icon {
  width: 20px;
  height: 4px;
}

.structure-editable-list__item-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 20px;
  top: 32px;
  background-color: $color-white;
  box-shadow: 0 16px 36px rgba($color-heading, 0.1);
  z-index: 2;
  font-size: 14px;
  border-radius: 2px;

  & > button {
    padding: 4px 24px;

    &:hover {
      background-color: $color-dividing-line-light;
    }
  }
}
