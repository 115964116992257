@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;

.constructor-select {
  position: relative;

  &:after {
    display: none;
  }

  & input:focus {
    box-shadow: none;
  }

  &.opened {
    .constructor-select__list-clear {
      opacity: 1;
    }

    .constructor-select__list-checkbox,
    .constructor-select__list-button {
      opacity: 1;
      transition-delay: 0.15s, 0s;
      transition-duration: 0.1s;
    }

    .constructor-select__button-icon {
      transform: rotate(180deg);
      fill: $color-text;
    }

    .constructor-select__list-wrapper {
      pointer-events: auto;
      box-shadow: 0 10px 12px rgba($color-heading, 0.04);
      transition-delay: 0.15s;

      &:before {
        transform: scaleY(1);
        transition-delay: 0s, 0s;
        transition-duration: 0.15s, 0s;
        opacity: 1;
      }
    }
  }
}

.constructor-select__button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 12px;
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
}

.constructor-select__button-icon {
  width: 10px;
  height: 6px;
  transition: transform 0.15s linear;
  fill: $color-field-frame;
}

.constructor-select__list {
  height: 100%;
  max-height: inherit;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.constructor-select__list-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 48px * 5 + 2px;
  border-radius: 2px;
  box-shadow: 0 10px 12px transparent;
  transition: box-shadow 0.1s linear 0s;
  pointer-events: none;
  z-index: 2;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
    background-color: $color-white;
    transition: transform 0s linear 0.15s, opacity 0.15s linear 0s;
    transform: scaleY(0);
    border-radius: inherit;
    opacity: 0;
    transform-origin: 0 0;
  }
}

.constructor-select__list-item {
  &:first-child {
    .constructor-select__list-checkbox,
    .constructor-select__list-button {
      padding-top: 10px;
    }
  }
}

.constructor-select__list-clear {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  position: relative;
  opacity: 0;
  z-index: 1;
  @include indent-vertical-pair(12);
  @include indent-horizontal-pair(16);
}

.constructor-select__list-checkbox,
.constructor-select__list-button {
  @include indent-vertical-pair(12);
  @include indent-horizontal-pair(16);
  font-size: 16px;
  line-height: 24px;
  display: flex;
  text-align: left;
  position: relative;
  width: 100%;
  background-color: transparent;
  opacity: 0;
  transition: opacity 0s linear, background-color 0.1s linear;
  z-index: 1;
  min-height: 48px;

  &:hover,
  &:focus {
    background-color: $color-light-gray-fill;
  }

  &.active {
    color: $color-interactive-element-active;

    &:before {
      content: '';
      position: absolute;
      top: 12px;
      left: 0;
      margin-top: 4px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml;utf8,<svg width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g fill='none' fill-rule='evenodd'><path d='M0 0h16v16H0z'/><path fill='%232B2B3C' d='M7.652 8.714L11.509 5l1.205 1.143L7.652 11 5 8.429l1.205-1.143z'/></g></svg>");
    }
  }
}

.constructor-select__list-wrapper:not(.admin-input) {
  .constructor-select__list-checkbox,
  .constructor-select__list-button {
    &.active {
      color: var(--color-interactive-element-active);
    }
  }
}
