@use '../../../../../node_modules/@make/ds2/scss/mixins' as *;
@use '../../../../../node_modules/@make/ds2/scss/variables' as *;

.constructor__add-person-form-buttons {
  display: flex;
  align-items: flex-end;

  .constructor-button {
    @include indent-outer-right(60);
  }

  &.error {
    @include indent-outer-bottom(16);
  }
}
