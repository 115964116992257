.constructor-sample-card {
  height: 220px;
  border: 1px solid #D9D9DC;
  border-radius: 2px;
  padding: 24px;

  &:hover {
    background: white;
    border-color: white;
  }

  &.active {
    background: #0061D9 !important;
    box-shadow: 0 10px 12px rgba(0, 0, 30, 0.04);
    border-color: #0061D9 !important;

    .constructor-sample-card__name, .constructor-sample-card__description {
      color: white;
    }
  }
}

.constructor-sample-card__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.constructor-sample-card__name {
  font-size: 16px;
  line-height: 24px;
  color: #2B2B3C;
}

.constructor-sample-card__description {
  font-size: 14px;
  line-height: 20px;
  color: #71717C;
}