////
/// The "News-row" component.
/// @group components
/// @author Alisa Dukina <a.dukina@make.st>
////

@use "sass:math";
@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;
@use "../../../../node_modules/@make/ds2/scss/functions/typography" as *;

.news-list__wrapper {
  padding-bottom: 0;
}

.news-section {
  @include indent-inner-top(32);
  overflow: hidden;

  .news--row:last-child {
    border-color: transparent;
  }

  @include screen-small-down {
    border-color: transparent;
  }

  @include screen-medium-up {
    @include indent-inner-bottom(32);
  }
}

.news-section--with-button {
  @include indent-outer-bottom(-32);

  @include screen-small-down {
    margin-bottom: 0;
  }

  .news--row:last-child {
    border-color: transparent;
  }
}

.news--row {
  transition: border-color 0.1s linear;

  @include screen-small-down {
    margin-bottom: 20px;
  }

  @include screen-medium-up {
    @include grid-template(repeat(8, 1fr));
    @include indent-inner-vertical-pair(32);
    border-bottom: 1px solid $color-dividing-line-light;
    column-gap: $grid-gutter-medium;
    display: grid;
  }

  @include screen-large-up {
    @include grid-template(1 3 2);
    column-gap: $grid-gutter-large;
  }

  @include screen-huge-up {
    column-gap: $grid-gutter-huge;
  }

  &:hover {
    border-color: $color-dividing-line;

    .news__description {
      color: $color-text;
    }
  }

  .news__publication-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .news__rubric {
    @include typo-make-sizes($typo-paragraph-small-sizes);
    display: flex;
    align-items: center;
    color: var(--color-interactive-element);
    margin-bottom: 8px;

    .icon {
      margin-left: 6px;
    }
  }

  .news__title {
    @include indent-outer-bottom(16);
    color: var(--background-color);
    margin-top: 0;

    @include screen-small-down {
      @include typo-make-sizes($typo-paragraph-text-sizes);
      margin-bottom: 0;
    }

    @include screen-medium-up {
      @include typo-make-sizes($typo-heading-h3-sizes, $isHeading: true);
      font-weight: 600;
    }
  }

  .news__illustration {
    margin-bottom: 0;

    @include screen-small-down {
      display: none;
    }

    @include screen-medium-down {
      @include indent-outer-top(16);
    }

    @include screen-medium-only {
      grid-column: 6 / span 3;
      margin-top: 0;
    }

    @include screen-medium-up {
      padding-bottom: 56.25%;
      position: relative;
      height: 56.25%;
    }

    .news__illustration-image {
      @include screen-medium-up {
        position: absolute;
      }
    }
  }

  .news__publication-date {
    @include screen-medium-down {
      @include indent-outer-bottom(12);
    }

    @include screen-medium-only {
      display: none;
    }
  }

  .news__link {
    order: 0;

    @include screen-medium-only {
      grid-column: 1 / span 5;
    }

    &:last-child {
      grid-column: 2 / span 2;

      @include screen-medium-only {
        grid-column: 1 / span 8;
      }
    }

    .news__publication-date {
      display: none;

      @include screen-medium-only {
        display: flex;
      }
    }
  }

  .news__description {
    @include typo-make-sizes($typo-paragraph-text-sizes);
    color: $color-hint;
    transition: color 0.1s linear;

    @include screen-small-down {
      display: none;
    }

    @include screen-medium-down {
      font-size: 16px;
      line-height: typo-text-line-height(16px);
    }
  }
}

.news-section__button-wrapper {
  @include indent-outer-top(48);
  // @include indent-outer-bottom(64);

  .button {
    width: 100%;

    &.hidden {
      // display: none;
    }
  }
}

.news-section__pagination {
  @include indent-outer-top(48);
  gap: 0;
}
