@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;


.constructor-slider__container {
  position: relative;

  .swiper-wrapper {
    gap: 0;
  }

  &:after,
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 48px;
    content: '';
    z-index: 2;
  }

  &:before {
    left: 0;
    background: linear-gradient(
                    90deg,
                    #f3f3f3 0%,
                    rgba(243, 243, 243, 0.0001) 100%
    );
  }

  &:after {
    right: 0;
    background: linear-gradient(
                    90deg,
                    rgba(243, 243, 243, 0.0001) 0%,
                    #f3f3f3 100%
    );
  }

  &.start-position {
    &:before {
      opacity: 0;
    }
  }

  &.end-position {
    &:after {
      opacity: 0;
    }
  }
}

.constructor-slider__buttons-block-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.constructor-slider__buttons-block {
  position: relative;
  width: 100%;
  height: 100%;

  .constructor-slider__buttons-block-gallery-slide {
    position: relative;
  }
}

.constructor-slider__button {
  position: absolute;
  top: calc(50% - 24px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba($color-heading, 0.08);
  opacity: 1;
  z-index: 3;
  transition: box-shadow 0.15s ease, opacity 0.15s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba($color-heading, 0.24);
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  @include screen-small-down {
    display: none;
  }
}

.constructor-slider__button-icon {
  width: 10px;
  height: 6px;
}

.constructor-slider__button--next {
  right: 24px;

  & .constructor-slider__button-icon {
    transform: rotate(-90deg);
  }
}

.constructor-slider__button--prev {
  left: 24px;

  & .constructor-slider__button-icon {
    transform: rotate(90deg);
  }
}

.swiper-slide {
  width: auto;
}
