@use "node_modules/@make/ds2/scss/variables" as *;
@use "node_modules/@make/ds2/scss/mixins" as *;

.constructor-banner-uploader__wrapper {
  .constructor-file-uploader__wrapper {
    display: block;
    margin-top: 0;
  }
}

.constructor-banner-uploader__label {
  font-size: 16px;
  line-height: 24px;
  @include indent-bottom(8);
}

.constructor-banner-uploader__preview {
  width: 100%;
  height: 152px;
  @include indent-outer-bottom(32);
}
