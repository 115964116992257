@use "node_modules/@make/ds2/scss/variables" as *;
@use "node_modules/@make/ds2/scss/mixins" as *;

.constructor__add-group-form {
  display: grid;
  gap: 20px;
  @include grid-medium-up(3 1 1);
  align-items: end;

  button {
    height: 48px;
  }
}
