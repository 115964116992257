@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.top-bar {
  position: fixed;
  left: 48px;
  top: 0;
  right: 0;
  height: 58px;
  background: white;
  z-index: 5;
}

.top-bar__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-bar__left-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-left: 8px;
}

.top-bar__right-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.top-bar__history-navigation-block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;

  svg {
    fill: #2B2B3C;
  }

  &:disabled {
    svg {
      fill: #D9D9DC;
    }
  }
}

.top-bar__publication-button {
  height: 100%;
  margin-left: 28px;
}

.constructor__work-zone-wrapper {
  @include indent-outer-top(64);
  margin-left: auto;
  margin-right: auto;

  @include screen-small-up {
    max-width: $grid-container-content-small-max;
  }

  @include screen-medium-up {
    max-width: $grid-container-content-medium-max;
  }

  @include screen-large-up {
    max-width: $grid-container-content-large-max;
  }

  @include screen-huge-up {
    max-width: $grid-container-content-huge-max;
  }
}
