@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;

.banners__container {
  @include indent-outer-top(40);
  //@include indent-outer-horizontal-pair(-40);
  //@include indent-horizontal-pair(40);
  align-items: center;
  box-sizing: content-box;
  display: flex;
  gap: 0;
  height: auto;
  transition-property: transform;
  //overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  @include screen-small-down {
    @include indent-outer-top(28);
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .constructor-slider__container {
    width: 100%;
  }

  .constructor-slider__button--prev {
    left: -24px;
  }

  .constructor-slider__button--next {
    right: -24px;
  }

  &.article__content {
    display: flex;
    grid-column: 1;
    padding-bottom: 0;

    & a {
      border-bottom: none;

      &:before {
        display: none;
      }
    }
  }
}

.banners__slider-wrapper {
  overflow: hidden;
  width: 100%;
}

.banners__slide-wrapper {
  max-height: 196px;

  @include screen-small-down {
    max-height: none;
  }

  &.swiper-slide {
    width: 100%;
  }
}

.banners__slide-wrapper--height2 {
  max-height: 392px;
}

.banners__slide {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: calc(196 / 1400 * 100%);
}

.banner__image {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.banner__counter-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 4px;

  &.active {
    background-color: var(--color-interactive-element);
    animation: bannersView linear;
    animation-duration: 5s;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left;
  }

  &.viewed {
    background-color: $color-white;
    width: 100%;
  }
}

@keyframes bannersView {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}


.banners__slide-with-text {
  position: relative;
}

.banners__slide-image-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00001E;
    z-index: 1;
    opacity: 0.32;
  }
}

.banners__slide-content {
  padding: 32px 72px 36px 40px;
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block !important;

  &:after {
    display: none !important;
  }
}

.banners__slide-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.banners__slide-content-text-block {
  width: 50%;
}

.banners__slide-content-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.banners__slide-content-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

.banners__slide-content-button-block {
  width: 50%;
  text-align: right;
}

.banners__slide-content-button {
  width: auto;
}
