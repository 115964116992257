@use "node_modules/@make/ds2/scss/variables" as *;

.constructor-color-scheme-card {
  position: relative;
  width: 100%;
  max-width: 256px;
  height: 104px;
  border: 1px solid $color-dividing-line;
  border-radius: 2px;
  padding: 16px;
  cursor: pointer;
  transition: background-color 0.15s ease, border-color 0.15s ease;

  &.active {
    background-color: #fff;
    border-color: #fff;
  }
}

.constructor-color-scheme-card__title {
  font-size: 16px;
  line-height: 24px;
}

.constructor-color-scheme-card__color-preview {
  position: absolute;
  right: 16px;
  bottom: 16px;
  height: 32px;
}

.constructor-color-scheme-card__color-indicator {
  display: inline-flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #000;

  &:last-child {
    margin-left: -7px;
  }
}
