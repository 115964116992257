@use "~@make/ds2/scss/mixins" as *;
@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/placeholders";

.search-results-page {
  @include indent-top(32);
  @include indent-bottom(80);

  .page-section__header {
    display: flex;
    align-items: flex-end;

    .page-section__heading {
      padding-bottom: 0;
      position: relative;
      display: flex;
      gap: 0;

      &::after {
        @include typo-make-sizes($typo-paragraph-small-sizes);
        @include indent-outer-left(8);
        color: $color-hint;
        content: attr(data-search-count);
        @include screen-medium-up {
          @include indent-outer-top(8);
        }
      }
    }
  }

  .tabs__buttons-scroll-wrapper {
    @include indent-top(32);
  }

  .site-search-wrapper {
    @include indent-outer-horizontal-pair(-32);
    @include indent-outer-top(-32);
    @include screen-medium-up {

      @include indent-outer-horizontal-pair(-40);
      width: 100%;
    }
  }

  .documents__filters-wrapper {
    &:last-child {
      @include indent-outer-bottom(-20);
      border-color: transparent;
    }
  }
}

.search-results__list {
  .news__publication-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    order: 0;

    @include screen-medium-down {
      display: none;
    }

    .news__topic-link {
      & + .news__publication-date {
        @include indent-outer-top(8);
      }
    }

    .news__publication-date {
      &::after {
        content: none;
      }
    }
  }

  .press-service__contact {
    @include grid-template(2 7 3);
    position: relative;

    &:hover {
      .contact__name {
        color: $color-interactive-element-hover;
      }
    }

    .contact__info {
      grid-column: span 1;

      @include screen-medium-down {
        grid-column: span 2;
      }

      .news__publication-info {
        display: none;

        @include screen-medium-down {
          display: flex;
        }
      }
    }

    .contact__name {
      transition: color 0.1s linear;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        content: "";
      }
    }

    .contact__photo {
      grid-column: span 1;
    }

    .contact__title {
      color: $color-text;

      @include screen-small-down {
        margin-bottom: 0;
      }
    }
  }

  .document-item,
  .news,
  .press-service__contact {
    &:last-child {
      border-color: transparent;

      @include screen-medium-up {
        @include indent-bottom(16);
      }
    }
  }

  .news {
    @include screen-small-down {
      @include indent-vertical-pair(32);
      border-bottom: 1px solid $color-dividing-line-light;
      margin-bottom: 0;
    }

    .news__title {
      @include screen-small-down {
        margin-bottom: 0;
      }
    }

    .news__publication-info {
      @include screen-medium-down {
        display: flex;
        flex-direction: row;
        grid-column: span 5;
        gap: 0;
      }

      .news__publication-date {
        @include screen-medium-down {
          margin: 0;
        }
      }

      .news__topic-link {
        &:not(:only-child) {
          &:after {
            @extend %typo-text-bullet;
            @include screen-medium-down {
              padding-left: 8px;
              padding-right: 8px;
            }

            @include screen-large-up {
              content: none;
            }
          }
        }
      }
    }

    .news__publication-date {
      @include screen-medium-down {
        display: block;
      }
    }

    .news__illustration {
      @include screen-medium-down {
        grid-row: 1 / span 3;
      }
    }
  }
}
