@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.constructor-title-h2 {
  @include indent-outer-bottom(32);

}

.constructor-title-h3 {
  @include indent-outer-bottom(24);
}
