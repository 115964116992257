.constructor-component-spoiler {
  font-size: 18px;
  line-height: 28px;
  color: #2B2B3C;
  margin-bottom: 40px;
  background: transparent;

  .accordion__wrapper {
    padding: 0;
  }

  &:before {
    background: transparent !important;
    box-shadow: none !important;
  }
}

.constructor-component-spoiler__name {
  margin-bottom: 16px;
}

.constructor-component-spoiler__text {

}

.constructor-component-spoiler__button {
  margin-top: 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #0061D9;
  padding: 0 !important;


  svg {
    margin-left: 4px;
    fill: #0061D9 !important;
  }
}