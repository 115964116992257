@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins/grid" as *;

.constructor-switch-controller__wrapper {
  display: flex;
  align-items: center;
}

.constructor-switch-controller__wrapper {
  justify-content: space-between;
  padding: 12px 16px;
  border: 1px solid $color-dividing-line;
  border-radius: 2px;
  transition: background-color 0.15s ease;

  &.active {
    background-color: $color-success;
    border-color: $color-success;
    color: $color-white;
  }
}

.constructor-switch-controller__title {
  font-size: 18px;
  line-height: 24px;
}
