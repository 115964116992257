@use '~@make/ds2/scss/variables' as *;
@use '~@make/ds2/scss/mixins' as *;

.constructor-history-navigation-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include indent-horizontal-pair(10);
  flex-shrink: 0;
  min-height: 58px;

  &:hover {
    .constructor-history-navigation-icon:not(.constructor-history-navigation-icon--disabled) {
      fill: $color-interactive-element-hover;
    }
  }
}

.constructor-history-navigation-icon {
  width: 16px;
  height: 18px;
  transition: fill 0.15s ease;

  &.constructor-history-navigation-icon--disabled {
    fill: $color-dividing-line;
  }
}

