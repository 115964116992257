@use "~@make/ds2/scss/variables" as *;

.file-manager {
  .chonky-toolbarRight {
    flex-wrap: wrap;
  }
}

.file-manager__top-block {
  height: 50px;
  //margin-bottom: 16px;
  display: flex;
  justify-content: right;

  .constructor-top-bar-button {
    padding-left: 16px;
    width: 50px;
    height: 50px;
    z-index: 1;
    background-color: #f3f3f3;

    .constructor-top-bar-button__text {
      color: white;
    }

    &:hover {
      .constructor-top-bar-button__text {
        color: #2b2b3c;
      }
    }
  }

  .constructor-top-bar-button__text {
    margin-right: 0;
  }
}

.file-manager__link-block {
  width: 100%;
  background-color: #f3f3f3;
  padding: 16px;
}

.file-manager__upload_list {
  max-height: calc(100% - 58px);
  overflow-y: auto;
  padding: 16px;
}