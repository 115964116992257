@use '../../../../node_modules/@make/ds2/scss/variables' as *;
@use '../../../../node_modules/@make/ds2/scss/mixins' as *;

.page-aside-menu {
  height: 500px;
  background: white;
}

.page-aside-menu__wrapper {
  @include indent-bottom(48);
  display: none;
  grid-column: 5/span 2;
  justify-self: center;
  width: 300px;

  @include screen-medium-up {
    display: block;
  }

  .anchor-menu__link {
    &:hover {
      color: var(--color-interactive-element-hover);
    }

    &.active {
      color: var(--color-interactive-element);
    }
  }

}
