.constructor-component__interview-block {
  margin-bottom: 40px;
}

.constructor-component__interview {
  margin-bottom: 20px;
}

.constructor-component__interview-question-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #2B2B3C;
  margin-bottom: 16px;
}

.constructor-component__interview-question {
  margin-left: 4px;
  width: 100%;

  .content-block--editor {
    width: 100%;
  }
}

.constructor-component__interview-answer-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 18px;
  line-height: 28px;
  color: #2B2B3C;
}

.constructor-component__interview-answer {
  margin-left: 4px;
  width: 100%;

  .content-block--editor {
    width: 100%;
  }
}