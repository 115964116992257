@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.constructor-contacts-with-map {
}

.constructor-contacts-with-map__map {
  width: 100%;
  height: 300px;
  @include indent-outer-bottom(32);
  @include screen-medium-up {
    height: 400px;
  }
}
