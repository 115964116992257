@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;

.constructor-pagination {
  display: flex;
}

.constructor-pagination__icon {
  width: 10px;
  height: 6px;
  transition: fill 0.15s ease;
}

.constructor-pagination__numbers {
  flex-wrap: wrap;
}

.constructor-pagination__numbers,
.constructor-pagination__button {
  display: flex;
  align-items: center;
  color: $color-interactive-element;
  transition: color 0.15s ease;

  &.active {
    color: $color-text;
  }

  &:hover {
    color: $color-interactive-element-hover;

    & > .constructor-pagination__icon {
      fill: $color-interactive-element-hover;
    }
  }

  &:active {
    color: $color-interactive-element-active;

    & > .constructor-pagination__icon {
      fill: $color-interactive-element-active;
    }
  }

  & > .constructor-pagination__icon {
    fill: $color-interactive-element;
  }
}

.constructor-pagination__numbers--public,
.constructor-pagination__button--public {
  color: var(--color-interactive-element);

  &:hover {
    color: var(--color-interactive-element-hover);

    & > .constructor-pagination__icon {
      fill: var(--color-interactive-element-hover);
    }
  }

  &:active {
    color: var(--color-interactive-element-active);

    & > .constructor-pagination__icon {
      fill: var(--color-interactive-element-active);
    }
  }

  & > .constructor-pagination__icon {
    fill: var(--color-interactive-element);
  }
}

.constructor-pagination__prev {
  @include indent-outer-right(24);

  & > .constructor-pagination__icon {
    @include indent-outer-right(12);
    transform: rotate(90deg);
  }
}

.constructor-pagination__next {
  @include indent-outer-left(24);

  & > .constructor-pagination__icon {
    @include indent-outer-left(12);
    transform: rotate(-90deg);
  }
}

.constructor-pagination__button--number:not(:last-child) {
  @include indent-outer-right(16);
}
