@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;
@use '~@make/ds2/scss/placeholders';

.constructor-paragraph {
  @include indent-outer-bottom(40);

  & p:not(:last-child),
  & div:not(:last-child) {
    @include typo-make-sizes($typo-paragraph-text-sizes);
    @include indent-outer-bottom(20);
  }

}

.constructor-paragraph, .article__content, .content-block--editor, .constructor-component__interview, .announce-block, .constructor-component-table {
  a {
    color: var(--color-interactive-element);

    &:visited {
      color: var(--color-interactive-element-visited);
    }

    &:hover {
      color: var(--color-interactive-element-hover);
    }
  }

  ul:not([class]), ol:not([class]) {
    @extend %typo-base-text-list;
  }

  ul:not([class]) {
    @extend %typo-unordered-text-list;
    @extend %typo-unordered-text-list-nested;

    > li > ol {
      @extend %typo-ordered-text-list;
      @extend %typo-ordered-text-list-nested;
    }

    > li > ul > li > ol {
      @extend %typo-ordered-text-list;
    }

    > li > ol > li > ul,
    > li > ul > li > ul {
      @extend %typo-unordered-text-list;
    }
  }

  ol:not([class]) {
    @extend %typo-ordered-text-list;
    @extend %typo-ordered-text-list-nested;

    > li > ul {
      @extend %typo-unordered-text-list;
      @extend %typo-unordered-text-list-nested;
    }

    > li > ol > li > ul {
      @extend %typo-unordered-text-list;
    }

    > li > ol > li > ol,
    > li > ul > li > ol {
      @extend %typo-ordered-text-list;
    }
  }
}
