@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;


.constructor-documents-list-item-block {
  @include indent-outer-bottom(20);
}

.constructor-documents-list-item__wrapper {
  position: relative;
}

.constructor-documents-list-item {
  display: flex;
  @include indent-outer-bottom(24);
  cursor: pointer;
  transition: border-color 0.15s ease;
  position: relative;

  &:not(:last-child) {
    @include indent-bottom(20);
    @include indent-outer-bottom(20);
    border-bottom: 1px solid $color-dividing-line-light;
  }

  &:hover {
    border-color: $color-dividing-line;

    .constructor-documents-list-item__name {
      color: var(--color-interactive-element-hover);
    }

    .constructor-documents-list-item__info {
      color: $color-text;
    }

  }
}

.constructor-documents-list-item__description {
  font-size: 14px;
  line-height: 20px;
  color: #71717C;
  padding-top: 24px;
}

.constructor-documents-list-item__icon {
  width: 48px;
  height: 48px;
  @include indent-outer-right(20);
}

.constructor-documents-list-item__name {
  @include typo-make-sizes($typo-paragraph-text-sizes);
  color: var(--color-interactive-element);
}

.constructor-documents-list-item-description {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
  @include indent-outer-top(24);
}

.constructor-documents-list-item__info {
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
}

.constructor-documents-list-item__name,
.constructor-documents-list-item__info {
  transition: color 0.15s ease;
}

.constructor-documents-list-item__info-item {
  @include indent-outer-right(24);
}

.constructor-documents-list-item__info-more {
  position: absolute;
  top: 0;
  right: 0;
}

.constructor-documents-list-item__info-more-icon {
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  fill: #2b2b3c;
  margin-left: 16px;
  opacity: 0.8;
}

.constructor-documents-list-item__info-more-modal {
  display: none;
  background: white;
  padding: 16px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border: 1px solid #e5e5e7;

  &.open {
    display: block;
  }
}