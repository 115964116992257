@use '~@make/ds2/scss/variables' as *;
@use '~@make/ds2/scss/mixins' as *;

.constructor-person-item {
  display: flex;
  @include indent-bottom(16);
  @include indent-outer-bottom(16);

  &:not(:last-child) {
    border-bottom: 1px solid $color-dividing-line;
  }

  &:hover {
    .constructor-person-item__name {
      color: $color-interactive-element-hover;
    }
  }

  &.not-active {
    //pointer-events: none;
    opacity: 0.48;
  }
}

.constructor-person-item__link {
  display: flex;
  flex-grow: 1;
}

.constructor-person-item__image {
  width: 44px;
  height: 44px;
  @include indent-outer-right(16);
  background-color: $color-white;
  overflow: hidden;
}

.constructor-person-item__info {
  flex-grow: 1;
}

.constructor-person-item__name {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  font-weight: 700;
  transition: color 0.15s ease;
}

.constructor-person-item__position {
  @include typo-make-sizes($typo-paragraph-small-sizes);
}

.constructor-person-item__button {
  &:not(:last-child) {
    @include indent-outer-right(24);
  }
}

.constructor-person-item__icon {
  width: 24px;
  height: 24px;
}
