@use "~@make/ds2/scss/variables" as *;

.constructor-warning {
  display: flex;
  align-items: center;
  gap: 0;
}

.constructor-warning__icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.constructor-warning__title {
  font-size: 14px;
  line-height: 20px;
}
