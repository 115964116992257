@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.constructor-image-in-text {
  @include indent-outer-bottom(48);
}

.constructor-image-in-text__image-wrapper {
  position: relative;
  width: 100%;
}

.constructor-image-in-text__image-caption {
  @include indent-outer-top(12);
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
}

.constructor-image-in-text__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  background-color: $color-white;
  color: $color-field-frame;
  @include typo-make-sizes($typo-paragraph-small-sizes);
}
