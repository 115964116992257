@use "node_modules/@make/ds2/scss/variables" as *;
@use "node_modules/@make/ds2/scss/mixins" as *;

.constructor-banners-list {
  display: grid;
  @include grid-medium-up(2 7 2);
  gap: 20px;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid $color-dividing-line;
  @include indent-bottom(16);
  @include indent-outer-bottom(16);
}

.constructor-banners-list__editing {
  border-bottom: 1px solid $color-dividing-line;
  @include indent-bottom(32);
  @include indent-outer-bottom(16);
}

.constructor-banners-list__preview {
  display: flex;
}

.constructor-banners-list__info {
  overflow-wrap: anywhere;
}

.constructor-banners-list__cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
}

.constructor-banners-list__controls {
  display: flex;
  align-self: start;
  align-items: center;
}

.constructor-banners-list__button-icon {
  width: 24px;
  height: 24px;
}
