@use "node_modules/@make/ds2/scss/variables/colors" as *;

.constructor-backlink {
  display: flex;
  align-items: center;
  width: 100%;
}

.constructor-backlink__icon {
  width: 16px;
  height: 12px;
  margin-right: 12px;
}
