@use "node_modules/@make/ds2/scss/variables" as *;
@use "node_modules/@make/ds2/scss/mixins" as *;

.constructor-elements-list {
  display: grid;
  @include grid-medium-up(8 2);
  gap: 20px;
  font-size: 16px;
  line-height: 24px;
  @include indent-bottom(16);
  @include indent-outer-bottom(16);
  border-bottom: 1px solid $color-dividing-line;

  &.without-edit-button {
    cursor: pointer;
  }
}

.constructor-elements-list__editing {
  @include indent-bottom(30);
  @include indent-outer-bottom(16);
  border-bottom: 1px solid $color-dividing-line;
}

.constructor-elements-list__info {
  display: flex;
}

.constructor-elements-list__title {
  font-weight: 500;
}

.constructor-elements-list__separator {
  display: inline-flex;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $color-dividing-line;
  @include indent-outer-horizontal-pair(8);
}

.constructor-elements-list__link-wrapper {
  display: flex;
  align-items: center;
  gap: 0;
  font-size: 14px;
  line-height: 20px;
}

.constructor-elements-list__controls {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: flex-end;
}

.constructor-elements-list__button-icon {
  width: 24px;
  height: 24px;
}

.constructor-elements-list__description {
  color: $color-hint;
}

.constructor-elements-list__link {
  display: flex;
}
