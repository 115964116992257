.gallery-block__wrapper {
  max-width: 100%;

  .page-section {
    display: block;
    column-gap: 0;
  }
}

.constructor-component__gallery {
  position: relative;
  max-width: 100%;

  .article-slider__wrapper {
    margin-top: 0;
    overflow: visible;

    .constructor-slider__button--next {
      right: -24px;
    }

    .constructor-slider__button--prev {
      left: -24px;
    }
  }

  .constructor-image-in-text__empty {
    height: 100%;
  }

  .article-slide {
    background: transparent;
  }

  .article-slider__list {
    height: auto !important;
  }
}

.constructor-component__gallery-slide {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
}