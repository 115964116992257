@use 'node_modules/@make/ds2/scss/variables' as *;
@use 'node_modules/@make/ds2/scss/mixins' as *;

.constructor-add-manager-form__header {
  display: flex;
  align-items: center;

  .page__section-subtitle {
    flex-grow: 1;
  }
}
