@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;

.constructor-map-point-item {
  @include indent-bottom(24);
  @include indent-outer-bottom(24);

  &:not(:last-child) {
    border-bottom: 1px solid $color-dividing-line-light;
  }
}

.constructor-map-point-item__name {
  @include indent-outer-bottom(16);
}

.constructor-map-point-item__address {
  @include typo-make-sizes($typo-paragraph-text-sizes);
  @include indent-outer-bottom(24);
}

.constructor-map-point-item__contacts {
  display: flex;
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  @include screen-small-down {
    flex-direction: column;
  }
}

.constructor-map-point-item__phone {
  @include indent-outer-bottom(20);

  @include screen-medium-up {
    margin-bottom: 0;
    @include indent-outer-right(100);
    transition: color 0.15s ease;

    &:hover {
      color: var(--color-interactive-element-hover);
    }

    &:active {
      color: var(--color-interactive-element-active);
    }
  }
}

.constructor-map-point-item__email {
  color: var(--color-interactive-element);
  transition: color 0.15s ease;

  &:hover {
    color: var(--color-interactive-element-hover);
  }

  &:active {
    color: var(--color-interactive-element-active);
  }
}
