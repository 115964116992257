@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.constructor-news-card {
  display: grid;
  @include grid-medium-up(8 2);
}

.constructor-news-card__container {
  display: grid;
  @include grid-medium-up(2 8);
  @include indent-bottom(24);
  gap: 20px;

  &.not-active {
    opacity: 0.48;
  }

  &:hover:not(.not-active) {
    .constructor-news-card__title {
      color: $color-interactive-element-hover;
    }
  }

  &:active {
    .constructor-news-card__title {
      color: $color-interactive-element-active;
    }
  }
}

.constructor-news-card__info-wrapper {
  display: grid;
  @include grid-medium-up(2 6);

  &.without__image {
    @include grid-medium-up(8);
  }
}

.constructor-news-card__cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.constructor-news-card__description,
.constructor-news-card__date {
  font-size: 14px;
  line-height: 20px;
}

.constructor-news-card__date {
  color: $color-hint;
}

.constructor-news-card__title {
  font-size: 16px;
  line-height: 24px;
  @include indent-bottom(8);
  transition: color 0.15s ease;
}

.constructor-news-card__delete-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.constructor-news-card__delete-button {
  &:hover {
    .constructor-news-card__delete-icon {
      fill: $color-interactive-element-hover;
    }
  }

  &:active {
    .constructor-news-card__delete-icon {
      fill: $color-interactive-element-active;
    }
  }
}

.constructor-news-card__delete-icon {
  width: 24px;
  height: 24px;
  transition: fill 0.15s ease;
}
