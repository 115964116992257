@use "~@make/ds2/scss/variables" as *;

.constructor-top-bar-button {
  padding-right: 16px;
  padding-left: 12px;
  background: transparent;
  height: 100%;
  box-shadow: none !important;
  border: none !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $color-light-gray-fill;
  }

  &.active {
    position: relative;
    background-color: $color-light-gray-fill;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: $color-interactive-element;
      content: '';
    }
  }
}

.constructor-top-bar-button__icon {
  width: 24px;
  height: 24px;
  fill: #2B2B3C;
  margin-right: 12px;
}

.constructor-top-bar-button__text {
  font-size: 16px;
  line-height: 24px;
  color: $color-text;
  font-weight: 500;
  margin-right: 6px;
}

.constructor-top-bar-button__dropdown-icon {
  width: 10px;
  height: 6px;
  transition: transform .15s linear;

  &.active {
    transform: rotate(-180deg);
  }
}
