.constructor__left-bar {
  max-width: 240px;
  width: 48px;
  height: 100vh;
  background: #00001E;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  transition: width .30s ease;
  overflow: hidden;

  &:hover {
    width: 100%;

    .constructor__left-bar-menu-button-title {
      opacity: 1;
    }

    .constructor__left-bar-site-title, .constructor__left-bar-site-domain {
      opacity: 0.48;
    }
  }
}

.constructor__left-bar-logo {
  width: 100%;
  height: 80px;
  padding-top: 12px;
  padding-bottom: 22px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.constructor__left-bar-logo-icon {
  width: 32px;
  height: 40px;
  margin-right: 16px;
  flex-shrink: 0;
}

.constructor__left-bar-menu-button {
  position: relative;
  margin-bottom: 12px;
  padding-left: 12px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  box-shadow: none !important;
  // padding: 0!important;
  border: none !important;
  cursor: pointer;

  &.active {
    background-color: #0061D9;

    .constructor__left-bar-menu-icon {
      opacity: 1;
    }
  }

  &:not(.active):hover {
    background-color: #2B2B3C;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2px;
      background-color: #0061D9;
      content: '';
    }
  }
}

.constructor__left-bar-menu-button-title {
  font-size: 16px;
  color: #fff;
  opacity: 0;
  transition: opacity .15s ease-in-out;
}

.constructor__left-bar-menu-button--sign-out {
  margin-top: 32px;
}

.constructor__left-bar-menu-icon {
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  fill: #fff;
  margin-right: 16px;
  opacity: 0.8;
}

.constructor__left-bar-site-logo-text {

}

.constructor__left-bar-site-title {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  opacity: 0;
  transition: opacity .15s ease-in-out;
  flex-shrink: 0;
}

.constructor__left-bar-site-domain {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  opacity: 0;
  transition: opacity .15s ease-in-out;
  flex-shrink: 0;
}
