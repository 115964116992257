@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.settings-panel__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-field-frame, 0.32);
  z-index: 2;
  opacity: 0;
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 100;
  transition: opacity 0.15s linear;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.settings-panel {
  position: relative;
  width: 844px;
  height: 100%;
  background: $color-light-gray-fill;
  transform: translateX(-200px);
  transition: transform 0.2s ease;
  overflow: auto;
  @include indent-horizontal-pair(64);
  @include indent-bottom(64);
  @include indent-top(24);
}

.ReactModal__Content--after-open {
  transform: translateX(0);
}

.ReactModal__Content--before-close {
  transform: translateX(-200px);
  transition: transform 0.15s linear;
}

.settings-panel__close {
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    fill: $color-field-frame;
    width: 14px;
    height: 14px;
  }
}

.settings-panel__header {
  @include indent-outer-bottom(40);
}

.settings-panel__title {
  @include indent-outer-bottom(12);
}

.settings-panel__subtitle {
  @include indent-outer-bottom(24);
}

.settings-panel__subtitle-description {
  color: $color-hint;
  font-weight: normal;
  @include typo-make-sizes($typo-paragraph-small-sizes);
  margin-top: 8px;

}

.settings-panel__description {
  color: $color-hint;
  @include typo-make-sizes($typo-paragraph-nav-sizes);

}

.settings-panel__row {
  @include indent-outer-bottom(20);
}

.settings-panel__row--table {
  .constructor-input-field__wrapper .textarea--big .editor__input {
    min-height: 100px;
  }
}

.settings-panel__row-2-columns {
  display: grid;
  gap: 20px;
  @include grid-medium-up(1 1);
  @include indent-outer-bottom(20);
}

.settings-panel__section {
  @include indent-outer-bottom(48);
}

.settings-panel__file-uploader {
  .constructor-file-uploader__wrapper:not(.with-file) {
    @include grid-medium-up(1);
  }
}

.settings-panel__file-uploader--with-buttons {
  display: grid;
  @include grid-medium-up(2 1);
  align-items: center;

  .constructor-file-uploader__wrapper:not(.with-file) {
    @include grid-medium-up(1);
  }
}

.settings-panel__file-uploader-buttons {
  justify-self: end;

  .settings-panel__cancel-button {
    @include indent-outer-right(20);
  }
}

.settings-panel__cancel-button {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
}

.settings-panel__buttons-row {
  @include indent-outer-top(24);

  .settings-panel__cancel-button {
    @include indent-outer-left(20);
  }
}

.settings-panel__file-uploader-label {
  @include typo-make-sizes($typo-paragraph-nav-sizes);
  @include indent-outer-bottom(8);
}
