@use "~@make/ds2/scss/variables" as *;
@use "~@make/ds2/scss/mixins" as *;

.constructor-video-in-text {
  @include indent-outer-bottom(48);
}

.constructor-script-in-text {
  padding: 10px;
  border: 1px solid #d9d9dc;
}

.constructor-video-in-text__expandable-description {
  &.expanded {
    & .constructor-video-in-text__description {
      max-height: 500px;
    }

    .constructor-video-in-text__dropdown-icon {
      transform: rotate(-180deg);
    }

  }

}

.constructor-video-in-text__description {
  @include indent-outer-top(12);
  @include indent-outer-bottom(9);
  @include typo-make-sizes($typo-paragraph-small-sizes);
  color: $color-hint;
  overflow: hidden;
  max-height: calc(20px * 2);
  transition: max-height 0.15s ease;

  @include screen-small-down {
    max-height: calc(16px * 2);
  }
}

.constructor-video-in-text__expanded-button {
  display: flex;
  align-items: center;
  color: var(--color-interactive-element);
  @include typo-make-sizes($typo-paragraph-nav-sizes);
}

.constructor-video-in-text__dropdown-icon {
  width: 10px;
  height: 6px;
  fill: var(--color-interactive-element);
  @include indent-outer-left(12);
  transition: transform 0.15s ease;
}


.constructor-video-in-text {
}
