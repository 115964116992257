@use "../../../../node_modules/@make/ds2/scss/variables" as *;
@use "../../../../node_modules/@make/ds2/scss/mixins" as *;

.main-page__constructor-component-wrapper {
  .page-section {
    padding-right: 0;
    padding-left: 0;
  }
}

.constructor-component__wrapper {
  box-sizing: border-box;
  @include indent-outer-horizontal-pair(-40);
  @include indent-horizontal-pair(40);
  position: relative;

  &.active {
    background: rgba(0, 97, 217, 0.04);
    border: 1px dashed $color-interactive-element;
    z-index: 2;

    .constructor-component__content {
      [contenteditable='true'] {
        background: white;
      }
    }

    .constructor-component__name {
      display: block;
    }

    .constructor-component__wrapper-menu, .constructor-component__wrapper-menu-wrapper {
      display: flex;
      align-items: center;
    }

    .constructor-component__add-new-component {
      display: flex;
    }
  }
}

.constructor-component__wrapper-menu-wrapper {
  position: relative;
}

.constructor-component__content {
  max-width: 100%;

  i {
    font-style: italic;
  }
}

.constructor-component__name,
.constructor-component__wrapper-menu {
  position: absolute;
  display: none;
}

.constructor-component__wrapper-menu {
  top: -46px;
  right: 0;
}

.constructor-component__name {
  left: -1px;
  top: -29px;
  @include typo-make-sizes($typo-paragraph-small-sizes);
  @include indent-vertical-pair(4);
  @include indent-left(8);
  @include indent-right(18);
  background-color: $color-interactive-element;
  color: $color-white;
  border-radius: 0 2px 0 0;
}

.constructor-component__edit-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  border-radius: 2px 0 0 2px;
  background-color: #fff;
  @include indent-right(16);
  @include indent-left(12);
  transition: box-shadow 0.15s ease;

  & > .constructor-component__icon {
    @include indent-outer-right(10);
  }
}

.constructor-component__delete-button {
  background-color: $color-error;
  border-radius: 0 2px 2px 0;
  transition: box-shadow 0.15s ease;

  & > .constructor-component__icon {
    fill: #fff;
  }
}

.constructor-component__drag {
  position: absolute;
  right: -36px;
  top: 0;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.constructor-component__delete-button {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.constructor-component__icon {
  height: 24px;
  width: 24px;
}

.constructor-component__duplicate {
  margin-left: 1px;
}

.constructor-component__moving-buttons {
  margin-left: 12px;
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 10px 12px rgba(0, 0, 30, 0.04);
  border-radius: 2px;
  width: 68px;
  justify-content: center;
}

.constructor-component__moving-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  background-color: #fff;
  transition: box-shadow 0.15s ease;
  border-radius: 2px;
  width: 50%;

  &.disable {
    svg {
      opacity: 0.16;
    }
  }
}

.constructor-component__moving-button--top {
  padding-left: 8px;
  padding-right: 4px;
}

.constructor-component__moving-button--bottom {
  padding-left: 4px;
  padding-right: 8px;
}

.constructor-component__delete-button,
.constructor-component__edit-button,
.constructor-component__moving-button {
  z-index: 1;

  &:hover {
    box-shadow: 0 10px 24px rgba($color-heading, 0.24);
    z-index: 2;
  }

  &:active {
    box-shadow: 0 10px 12px rgba($color-heading, 0.04);
  }
}